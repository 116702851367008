import { lazy } from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';

export function checkIsMobile(cb?: (res: boolean) => any) {
  const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : '';

  const res = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
  cb?.(res);
  return res;
}

const AppMarket = lazy(() => import(`./${checkIsMobile() ? 'mobilePages' : 'pages'}/AppMarket/AppMarket`));

const AppDetail = lazy(() => import(`./${checkIsMobile() ? 'mobilePages' : 'pages'}/AppDetail/AppDetail`));

export const router = createBrowserRouter([
  {
    path: '/:type?',
    element: <AppMarket />,
  },
  {
    path: '/detail/:id',
    element: <AppDetail />,
  },
  {
    path: '*',
    loader: () => {
      return redirect('/');
    },
  },
]);
