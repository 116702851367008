import { Suspense } from 'react';
import './App.css';
import { router } from './router';
import { RouterProvider } from 'react-router-dom';
import { Spin } from 'antd';
import zh from '@nutui/nutui-react/dist/locales/zh-CN';
import { ConfigProvider } from '@nutui/nutui-react';

import '@nutui/nutui-react/dist/style.css';
import { defaultTheme } from './components';
import { css } from '@emotion/css';

function App() {
  return (
    <Suspense
      fallback={
        <div
          className={css`
            height: 100vh;
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Spin />
        </div>
      }
    >
      <ConfigProvider theme={defaultTheme} locale={zh}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </Suspense>
  );
}

export default App;
