import './components.less';

const buttonTheme = {
  // nutuiButtonPrimary-
  nutuiButtonDefaultFontSize: '14px',
};
export const defaultTheme = {
  ...buttonTheme,
  nutuiBrandColor: '#165dff',
  nutuiBrandColorStart: '#165dff',
  nutuiBrandColorEnd: '#165dff',
  '--nutui-tabs-titles-background-color': '#fff',
  nutuiLoadingIconColor: '#396aca',
};
